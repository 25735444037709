unit Landing;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.ExtCtrls;

type
  TFrmLanding = class(TForm)
    WebPanel1: TPanel;
    WebButton1: TButton;
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmLanding: TFrmLanding;

implementation

Uses ServiceCalls;

{$R *.dfm}

procedure TFrmLanding.WebButton1Click(Sender: TObject);
begin
  Application.CreateForm(TFrmServiceCalls, FrmServiceCalls);
  FrmServiceCalls.Show;
end;

procedure TFrmLanding.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  WebButton1 := TButton.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  try
    Name := 'FrmLanding';
    Width := 335;
    Height := 504;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 335;
    WebPanel1.Height := 504;
    WebPanel1.ElementClassName := 'card';
    WebPanel1.Align := alClient;
    WebPanel1.ElementBodyClassName := 'card-body';
    WebPanel1.ElementFont := efCSS;
    WebButton1.SetParentComponent(WebPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 27;
    WebButton1.Top := 16;
    WebButton1.Width := 254;
    WebButton1.Height := 65;
    WebButton1.Caption := 'Service Calls';
    WebButton1.ElementClassName := 'btn btn-lge btn-info';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssPercent;
    WebButton1.WidthPercent := 90.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
  finally
    WebPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
  end;
end;

end.