unit ServiceCalls;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Data.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, Vcl.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.DB,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.ExtCtrls, StrUtils;

type
  TFrmServiceCalls = class(TForm)
    WebDBResponsiveGrid1: TDBResponsiveGrid;
    WDS_ServiceCalls: TXDataWebDataSet;
    DS_ServiceCalls: TDataSource;
    WDS_Cust: TXDataWebDataSet;
    DS_Cust: TDataSource;
    WDS_ServiceCallsServiceCallsID: TIntegerField;
    WDS_ServiceCallsDate: TDateTimeField;
    WDS_ServiceCallsCust: TIntegerField;
    WDS_ServiceCallsIndividualCalling: TStringField;
    WDS_ServiceCallsEmergencyYN: TStringField;
    WDS_ServiceCallsWBSpecAssignNum: TIntegerField;
    WDS_ServiceCallsAssignedDate: TDateTimeField;
    WDS_ServiceCallsWBSpecCompletedNum: TIntegerField;
    WDS_ServiceCallsCompletedDate: TDateTimeField;
    WDS_CustCust: TIntegerField;
    WDS_CustCustName: TStringField;
    WDS_CustSRNum: TIntegerField;
    WDS_CustAddress1: TStringField;
    WDS_CustAddress2: TStringField;
    WDS_CustCity: TStringField;
    WDS_CustState: TStringField;
    WDS_CustTermsExplained: TStringField;
    WDS_CustLatitude: TFloatField;
    WDS_CustLongitude: TFloatField;
    WP_CallCust: TPanel;
    WebDBLabel1: TDBLabel;
    WebDBLabel2: TDBLabel;
    WebDBLabel3: TDBLabel;
    WebDBLabel4: TDBLabel;
    WebDBLabel5: TDBLabel;
    WP_CallDetail: TPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebLabel1: TLabel;
    WebComboBox1: TComboBox;
    WebButton3: TButton;
    WDS_Drop: TXDataWebDataSet;
    WDS_DropChemSpecNum: TIntegerField;
    WDS_DropFirstName: TStringField;
    WDS_DropLastName: TStringField;
    WDS_DropEmail: TStringField;
    WDS_DropOnlineUser: TStringField;
    WDS_DropOnlinePass: TStringField;
    DS_Drop: TDataSource;
    WebButton4: TButton;
    WebTimer1: TTimer;
    WebLabel2: TLabel;
    WebDBMemo1: TDBMemo;
    WebButton5: TButton;
    WDS_ServiceCallsNotes: TStringField;
    WDS_ServiceCallsServiceNotes: TStringField;
    WebDBMemo2: TDBMemo;
    WebLabel3: TLabel;
    procedure WebFormShow(Sender: TObject);
    procedure WDS_ServiceCallsAfterScroll(DataSet: TDataSet);
    procedure WebButton3Click(Sender: TObject);
    procedure WebDBResponsiveGrid1DblClick(Sender: TObject);
    procedure WDS_DropAfterOpen(DataSet: TDataSet);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
    procedure WebButton5Click(Sender: TObject);
    procedure WDS_ServiceCallsAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmServiceCalls: TFrmServiceCalls;

implementation

Uses Data;

{$R *.dfm}

procedure TFrmServiceCalls.WDS_DropAfterOpen(DataSet: TDataSet);
begin
  WebComboBox1.Items.Clear;
  WebComboBox1.Items.Add('Select a Specialist');
  while not WDS_Drop.Eof do
  begin
    WebComboBox1.Items.Add(WDS_DropChemSpecNum.AsString+' - '+WDS_DropFirstName.AsString+' '+WDS_DropLastName.AsString);
    WDS_Drop.Next;
  end;
  WDS_Drop.Close;
  WebComboBox1.ItemIndex := 0;
end;

procedure TFrmServiceCalls.WDS_ServiceCallsAfterOpen(DataSet: TDataSet);
begin
  if WDS_ServiceCalls.RecordCount = 0 then
  begin
    ShowMessage('No Open Service Calls');
  end;
end;

procedure TFrmServiceCalls.WDS_ServiceCallsAfterScroll(DataSet: TDataSet);
begin
  if WDS_ServiceCallsCust.AsString <> '' then
  begin
    WDS_Cust.Close;
    WDS_Cust.QueryString := '$filter=(Cust eq '''+WDS_ServiceCallsCust.AsString+''')';
    WDS_Cust.Load;
  end;
end;

procedure TFrmServiceCalls.WebButton1Click(Sender: TObject);
begin
  WDS_ServiceCalls.Edit;
  WDS_ServiceCallsCompletedDate.AsDateTime := Now();
  WDS_ServiceCallsWBSpecCompletedNum.AsInteger := Dm.WDS_UserChemSpecNum.AsInteger;
  WDS_ServiceCalls.Post;
  WDS_ServiceCalls.ApplyUpdates;
  ShowMessage('Service Call Marked as Completed');
  WP_CallDetail.Visible := False;
  WebDBResponsiveGrid1.Visible := True;
  WebTimer1.Enabled := True;
end;

procedure TFrmServiceCalls.WebButton2Click(Sender: TObject);
begin
  if WebComboBox1.ItemIndex > 0 then
  begin
    WDS_ServiceCalls.Edit;
    WDS_ServiceCallsWBSpecAssignNum.AsString := LeftStr(WebComboBox1.Items[WebComboBox1.ItemIndex],1);
    WDS_ServiceCalls.Post;
    WDS_ServiceCalls.ApplyUpdates;
    ShowMessage('Service Call Migrated');
    WP_CallDetail.Visible := False;
    WebDBResponsiveGrid1.Visible := True;
    WebTimer1.Enabled := True;
  end
  else
  begin
    ShowMessage('Must Select Specialist from Dropdown Area');
    Exit;
  end;
end;

procedure TFrmServiceCalls.WebButton3Click(Sender: TObject);
begin
  WP_CallDetail.Visible := False;
  WebDBResponsiveGrid1.Visible := True;
  WebTimer1.Enabled := True;
end;

procedure TFrmServiceCalls.WebButton4Click(Sender: TObject);
begin
  WebTimer1.Enabled := False;
  WebDBResponsiveGrid1.Visible := False;
  WP_CallDetail.Visible := True;
  WP_CallDetail.BringToFront;
end;

procedure TFrmServiceCalls.WebButton5Click(Sender: TObject);
begin
  WDS_ServiceCalls.Post;
  WDS_ServiceCalls.ApplyUpdates;
end;

procedure TFrmServiceCalls.WebDBResponsiveGrid1DblClick(Sender: TObject);
begin
  WebDBResponsiveGrid1.Visible := False;
  WP_CallDetail.Visible := True;
  WP_CallDetail.BringToFront;
end;

procedure TFrmServiceCalls.WebFormShow(Sender: TObject);
begin
  WDS_Drop.QueryString := '$filter=(ChemSpecNum gt 0)&orderby=ChemSpecNum';
  WDS_Drop.Load;
  if Dm.WDS_UserManager.AsString = 'Y' then
  begin
    WDS_ServiceCalls.QueryString := '$filter=(WBSpecCompletedNum eq null)&orderby=Date';
    WDS_ServiceCalls.Load;
  end
  else
  begin
    WDS_ServiceCalls.QueryString := '$filter=(WBSpecAssignNum eq '''+Dm.WDS_UserChemSpecNum.AsString+''' and WBSpecCompletedNum eq null)&orderby=Date';
    WDS_ServiceCalls.Load;
  end;
end;

procedure TFrmServiceCalls.WebTimer1Timer(Sender: TObject);
begin
  {WDS_ServiceCalls.Close;
  if Dm.WDS_UserManager.AsString = 'Y' then
  begin
    WDS_ServiceCalls.QueryString := '$filter=(WBSpecCompletedNum eq null)&orderby=Date';
    WDS_ServiceCalls.Load;
  end
  else
  begin
    WDS_ServiceCalls.QueryString := '$filter=(WBSpecAssignNum eq '''+Dm.WDS_UserChemSpecNum.AsString+''' and WBSpecCompletedNum eq null)&orderby=Date';
    WDS_ServiceCalls.Load;
  end;  }
  WebDBResponsiveGrid1.Refresh;
end;

procedure TFrmServiceCalls.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebDBResponsiveGrid1 := TDBResponsiveGrid.Create(Self);
  WP_CallCust := TPanel.Create(Self);
  WebDBLabel1 := TDBLabel.Create(Self);
  WebDBLabel2 := TDBLabel.Create(Self);
  WebDBLabel3 := TDBLabel.Create(Self);
  WebDBLabel4 := TDBLabel.Create(Self);
  WebDBLabel5 := TDBLabel.Create(Self);
  WebButton4 := TButton.Create(Self);
  WP_CallDetail := TPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebComboBox1 := TComboBox.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebDBMemo1 := TDBMemo.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebDBMemo2 := TDBMemo.Create(Self);
  WDS_ServiceCalls := TXDataWebDataSet.Create(Self);
  WDS_ServiceCallsServiceCallsID := TIntegerField.Create(Self);
  WDS_ServiceCallsDate := TDateTimeField.Create(Self);
  WDS_ServiceCallsCust := TIntegerField.Create(Self);
  WDS_ServiceCallsIndividualCalling := TStringField.Create(Self);
  WDS_ServiceCallsEmergencyYN := TStringField.Create(Self);
  WDS_ServiceCallsWBSpecAssignNum := TIntegerField.Create(Self);
  WDS_ServiceCallsAssignedDate := TDateTimeField.Create(Self);
  WDS_ServiceCallsWBSpecCompletedNum := TIntegerField.Create(Self);
  WDS_ServiceCallsCompletedDate := TDateTimeField.Create(Self);
  WDS_ServiceCallsNotes := TStringField.Create(Self);
  WDS_ServiceCallsServiceNotes := TStringField.Create(Self);
  DS_ServiceCalls := TDataSource.Create(Self);
  WDS_Cust := TXDataWebDataSet.Create(Self);
  WDS_CustCust := TIntegerField.Create(Self);
  WDS_CustCustName := TStringField.Create(Self);
  WDS_CustSRNum := TIntegerField.Create(Self);
  WDS_CustAddress1 := TStringField.Create(Self);
  WDS_CustAddress2 := TStringField.Create(Self);
  WDS_CustCity := TStringField.Create(Self);
  WDS_CustState := TStringField.Create(Self);
  WDS_CustTermsExplained := TStringField.Create(Self);
  WDS_CustLatitude := TFloatField.Create(Self);
  WDS_CustLongitude := TFloatField.Create(Self);
  DS_Cust := TDataSource.Create(Self);
  WDS_Drop := TXDataWebDataSet.Create(Self);
  WDS_DropChemSpecNum := TIntegerField.Create(Self);
  WDS_DropFirstName := TStringField.Create(Self);
  WDS_DropLastName := TStringField.Create(Self);
  WDS_DropEmail := TStringField.Create(Self);
  WDS_DropOnlineUser := TStringField.Create(Self);
  WDS_DropOnlinePass := TStringField.Create(Self);
  DS_Drop := TDataSource.Create(Self);
  WebTimer1 := TTimer.Create(Self);

  WebDBResponsiveGrid1.BeforeLoadDFMValues;
  WP_CallCust.BeforeLoadDFMValues;
  WebDBLabel1.BeforeLoadDFMValues;
  WebDBLabel2.BeforeLoadDFMValues;
  WebDBLabel3.BeforeLoadDFMValues;
  WebDBLabel4.BeforeLoadDFMValues;
  WebDBLabel5.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WP_CallDetail.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebComboBox1.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebDBMemo1.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebDBMemo2.BeforeLoadDFMValues;
  WDS_ServiceCalls.BeforeLoadDFMValues;
  WDS_ServiceCallsServiceCallsID.BeforeLoadDFMValues;
  WDS_ServiceCallsDate.BeforeLoadDFMValues;
  WDS_ServiceCallsCust.BeforeLoadDFMValues;
  WDS_ServiceCallsIndividualCalling.BeforeLoadDFMValues;
  WDS_ServiceCallsEmergencyYN.BeforeLoadDFMValues;
  WDS_ServiceCallsWBSpecAssignNum.BeforeLoadDFMValues;
  WDS_ServiceCallsAssignedDate.BeforeLoadDFMValues;
  WDS_ServiceCallsWBSpecCompletedNum.BeforeLoadDFMValues;
  WDS_ServiceCallsCompletedDate.BeforeLoadDFMValues;
  WDS_ServiceCallsNotes.BeforeLoadDFMValues;
  WDS_ServiceCallsServiceNotes.BeforeLoadDFMValues;
  DS_ServiceCalls.BeforeLoadDFMValues;
  WDS_Cust.BeforeLoadDFMValues;
  WDS_CustCust.BeforeLoadDFMValues;
  WDS_CustCustName.BeforeLoadDFMValues;
  WDS_CustSRNum.BeforeLoadDFMValues;
  WDS_CustAddress1.BeforeLoadDFMValues;
  WDS_CustAddress2.BeforeLoadDFMValues;
  WDS_CustCity.BeforeLoadDFMValues;
  WDS_CustState.BeforeLoadDFMValues;
  WDS_CustTermsExplained.BeforeLoadDFMValues;
  WDS_CustLatitude.BeforeLoadDFMValues;
  WDS_CustLongitude.BeforeLoadDFMValues;
  DS_Cust.BeforeLoadDFMValues;
  WDS_Drop.BeforeLoadDFMValues;
  WDS_DropChemSpecNum.BeforeLoadDFMValues;
  WDS_DropFirstName.BeforeLoadDFMValues;
  WDS_DropLastName.BeforeLoadDFMValues;
  WDS_DropEmail.BeforeLoadDFMValues;
  WDS_DropOnlineUser.BeforeLoadDFMValues;
  WDS_DropOnlinePass.BeforeLoadDFMValues;
  DS_Drop.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'FrmServiceCalls';
    Width := 441;
    Height := 762;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebDBResponsiveGrid1.SetParentComponent(Self);
    WebDBResponsiveGrid1.Name := 'WebDBResponsiveGrid1';
    WebDBResponsiveGrid1.Left := 0;
    WebDBResponsiveGrid1.Top := 625;
    WebDBResponsiveGrid1.Width := 441;
    WebDBResponsiveGrid1.Height := 721;
    WebDBResponsiveGrid1.HeightStyle := ssPercent;
    WebDBResponsiveGrid1.HeightPercent := 80.000000000000000000;
    WebDBResponsiveGrid1.Align := alTop;
    WebDBResponsiveGrid1.ElementFont := efCSS;
    WebDBResponsiveGrid1.ItemIndex := 0;
    WebDBResponsiveGrid1.Options.ItemBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemSelectedColor := clHighlight;
    WebDBResponsiveGrid1.Options.ItemSelectedBorderColor := clBlack;
    WebDBResponsiveGrid1.Options.ItemSelectedTextColor := clHighlightText;
    WebDBResponsiveGrid1.Options.ItemTemplate := 'C#:(%Cust%)  Entered:(%Date%) <br>Emergency: (%EmergencyYN%) Caller:(%IndividualCalling%) <BR> (%Notes%)';
    WebDBResponsiveGrid1.Options.ItemWidthMin := 300;
    SetEvent(WebDBResponsiveGrid1, Self, 'OnDblClick', 'WebDBResponsiveGrid1DblClick');
    WebDBResponsiveGrid1.DataSource := DS_ServiceCalls;
    WP_CallCust.SetParentComponent(Self);
    WP_CallCust.Name := 'WP_CallCust';
    WP_CallCust.Left := 0;
    WP_CallCust.Top := 631;
    WP_CallCust.Width := 441;
    WP_CallCust.Height := 131;
    WP_CallCust.ElementClassName := 'card';
    WP_CallCust.HeightStyle := ssPercent;
    WP_CallCust.HeightPercent := 15.000000000000000000;
    WP_CallCust.Align := alBottom;
    WP_CallCust.ChildOrder := 2;
    WP_CallCust.ElementBodyClassName := 'card-body';
    WP_CallCust.ElementFont := efCSS;
    WebDBLabel1.SetParentComponent(WP_CallCust);
    WebDBLabel1.Name := 'WebDBLabel1';
    WebDBLabel1.Left := 16;
    WebDBLabel1.Top := 7;
    WebDBLabel1.Width := 90;
    WebDBLabel1.Height := 18;
    WebDBLabel1.Caption := 'WebDBLabel1';
    WebDBLabel1.ElementFont := efCSS;
    WebDBLabel1.HeightStyle := ssAuto;
    WebDBLabel1.HeightPercent := 100.000000000000000000;
    WebDBLabel1.WidthPercent := 100.000000000000000000;
    WebDBLabel1.DataField := 'CustName';
    WebDBLabel1.DataSource := DS_Cust;
    WebDBLabel2.SetParentComponent(WP_CallCust);
    WebDBLabel2.Name := 'WebDBLabel2';
    WebDBLabel2.Left := 16;
    WebDBLabel2.Top := 31;
    WebDBLabel2.Width := 90;
    WebDBLabel2.Height := 18;
    WebDBLabel2.Caption := 'WebDBLabel1';
    WebDBLabel2.ElementFont := efCSS;
    WebDBLabel2.HeightStyle := ssAuto;
    WebDBLabel2.HeightPercent := 100.000000000000000000;
    WebDBLabel2.WidthPercent := 100.000000000000000000;
    WebDBLabel2.DataField := 'Address1';
    WebDBLabel2.DataSource := DS_Cust;
    WebDBLabel3.SetParentComponent(WP_CallCust);
    WebDBLabel3.Name := 'WebDBLabel3';
    WebDBLabel3.Left := 16;
    WebDBLabel3.Top := 55;
    WebDBLabel3.Width := 90;
    WebDBLabel3.Height := 18;
    WebDBLabel3.Caption := 'WebDBLabel1';
    WebDBLabel3.ElementFont := efCSS;
    WebDBLabel3.HeightStyle := ssAuto;
    WebDBLabel3.HeightPercent := 100.000000000000000000;
    WebDBLabel3.WidthPercent := 100.000000000000000000;
    WebDBLabel3.DataField := 'Address2';
    WebDBLabel3.DataSource := DS_Cust;
    WebDBLabel4.SetParentComponent(WP_CallCust);
    WebDBLabel4.Name := 'WebDBLabel4';
    WebDBLabel4.Left := 16;
    WebDBLabel4.Top := 79;
    WebDBLabel4.Width := 90;
    WebDBLabel4.Height := 18;
    WebDBLabel4.Caption := 'WebDBLabel1';
    WebDBLabel4.ElementFont := efCSS;
    WebDBLabel4.HeightStyle := ssAuto;
    WebDBLabel4.HeightPercent := 100.000000000000000000;
    WebDBLabel4.WidthPercent := 100.000000000000000000;
    WebDBLabel4.DataField := 'City';
    WebDBLabel4.DataSource := DS_Cust;
    WebDBLabel5.SetParentComponent(WP_CallCust);
    WebDBLabel5.Name := 'WebDBLabel5';
    WebDBLabel5.Left := 128;
    WebDBLabel5.Top := 79;
    WebDBLabel5.Width := 90;
    WebDBLabel5.Height := 18;
    WebDBLabel5.Caption := 'WebDBLabel1';
    WebDBLabel5.ElementFont := efCSS;
    WebDBLabel5.HeightStyle := ssAuto;
    WebDBLabel5.HeightPercent := 100.000000000000000000;
    WebDBLabel5.WidthPercent := 100.000000000000000000;
    WebDBLabel5.DataField := 'State';
    WebDBLabel5.DataSource := DS_Cust;
    WebButton4.SetParentComponent(WP_CallCust);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 296;
    WebButton4.Top := 8;
    WebButton4.Width := 129;
    WebButton4.Height := 33;
    WebButton4.Caption := 'Process';
    WebButton4.ChildOrder := 5;
    WebButton4.ElementClassName := 'btn btn-primary';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WP_CallDetail.SetParentComponent(Self);
    WP_CallDetail.Name := 'WP_CallDetail';
    WP_CallDetail.Left := 0;
    WP_CallDetail.Top := 0;
    WP_CallDetail.Width := 441;
    WP_CallDetail.Height := 625;
    WP_CallDetail.ElementClassName := 'card';
    WP_CallDetail.HeightPercent := 85.000000000000000000;
    WP_CallDetail.Align := alTop;
    WP_CallDetail.ChildOrder := 6;
    WP_CallDetail.ElementBodyClassName := 'card-body';
    WP_CallDetail.ElementFont := efCSS;
    WP_CallDetail.Visible := False;
    WebLabel1.SetParentComponent(WP_CallDetail);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 216;
    WebLabel1.Width := 95;
    WebLabel1.Height := 18;
    WebLabel1.Caption := 'New Specialist:';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(WP_CallDetail);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 379;
    WebLabel2.Width := 315;
    WebLabel2.Height := 18;
    WebLabel2.Caption := 'Additional Notes (Will be included in Email to SR):';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(WP_CallDetail);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 24;
    WebLabel3.Top := 272;
    WebLabel3.Width := 121;
    WebLabel3.Height := 18;
    WebLabel3.Caption := 'Notes From Caller:';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(WP_CallDetail);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 33;
    WebButton1.Top := 78;
    WebButton1.Width := 273;
    WebButton1.Height := 57;
    WebButton1.Caption := 'Mark as Finished';
    WebButton1.ElementClassName := 'btn btn-info btn-large';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 85.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WP_CallDetail);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 33;
    WebButton2.Top := 141;
    WebButton2.Width := 273;
    WebButton2.Height := 65;
    WebButton2.Caption := 'Send to Different Specialist';
    WebButton2.ChildOrder := 1;
    WebButton2.ElementClassName := 'btn btn-warning  btn-large';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 85.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebComboBox1.SetParentComponent(WP_CallDetail);
    WebComboBox1.Name := 'WebComboBox1';
    WebComboBox1.Left := 80;
    WebComboBox1.Top := 240;
    WebComboBox1.Width := 249;
    WebComboBox1.Height := 26;
    WebComboBox1.ElementClassName := 'custom-select';
    WebComboBox1.ElementFont := efCSS;
    WebComboBox1.HeightStyle := ssAuto;
    WebComboBox1.HeightPercent := 100.000000000000000000;
    WebComboBox1.WidthPercent := 100.000000000000000000;
    WebComboBox1.ItemIndex := -1;
    WebButton3.SetParentComponent(WP_CallDetail);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 33;
    WebButton3.Top := 15;
    WebButton3.Width := 273;
    WebButton3.Height := 57;
    WebButton3.Caption := 'Close';
    WebButton3.ChildOrder := 4;
    WebButton3.ElementClassName := 'btn btn-danger btn-large';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebDBMemo1.SetParentComponent(WP_CallDetail);
    WebDBMemo1.Name := 'WebDBMemo1';
    WebDBMemo1.Left := 33;
    WebDBMemo1.Top := 403;
    WebDBMemo1.Width := 296;
    WebDBMemo1.Height := 161;
    WebDBMemo1.AutoSize := False;
    WebDBMemo1.ElementClassName := 'form-control';
    WebDBMemo1.ElementFont := efCSS;
    WebDBMemo1.HeightPercent := 100.000000000000000000;
    WebDBMemo1.Lines.BeginUpdate;
    try
      WebDBMemo1.Lines.Clear;
      WebDBMemo1.Lines.Add('WebDBMemo1');
    finally
      WebDBMemo1.Lines.EndUpdate;
    end;
    WebDBMemo1.SelLength := 0;
    WebDBMemo1.SelStart := 0;
    WebDBMemo1.WidthPercent := 100.000000000000000000;
    WebDBMemo1.DataField := 'ServiceNotes';
    WebDBMemo1.DataSource := DS_ServiceCalls;
    WebButton5.SetParentComponent(WP_CallDetail);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 201;
    WebButton5.Top := 578;
    WebButton5.Width := 113;
    WebButton5.Height := 41;
    WebButton5.Caption := 'Save';
    WebButton5.ChildOrder := 7;
    WebButton5.ElementClassName := 'btn btn-light';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightStyle := ssAuto;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebDBMemo2.SetParentComponent(WP_CallDetail);
    WebDBMemo2.Name := 'WebDBMemo2';
    WebDBMemo2.Left := 33;
    WebDBMemo2.Top := 296;
    WebDBMemo2.Width := 296;
    WebDBMemo2.Height := 73;
    WebDBMemo2.AutoSize := False;
    WebDBMemo2.ElementClassName := 'form-control';
    WebDBMemo2.ElementFont := efCSS;
    WebDBMemo2.HeightPercent := 100.000000000000000000;
    WebDBMemo2.Lines.BeginUpdate;
    try
      WebDBMemo2.Lines.Clear;
      WebDBMemo2.Lines.Add('WebDBMemo1');
    finally
      WebDBMemo2.Lines.EndUpdate;
    end;
    WebDBMemo2.ReadOnly := True;
    WebDBMemo2.SelLength := 0;
    WebDBMemo2.SelStart := 0;
    WebDBMemo2.WidthPercent := 100.000000000000000000;
    WebDBMemo2.DataField := 'Notes';
    WebDBMemo2.DataSource := DS_ServiceCalls;
    WDS_ServiceCalls.SetParentComponent(Self);
    WDS_ServiceCalls.Name := 'WDS_ServiceCalls';
    WDS_ServiceCalls.AfterOpen := WDS_ServiceCallsAfterOpen;
    WDS_ServiceCalls.AfterScroll := WDS_ServiceCallsAfterScroll;
    WDS_ServiceCalls.EntitySetName := 'WW_ServiceCalls';
    WDS_ServiceCalls.Connection := Dm.ApiConnection;
    WDS_ServiceCalls.Left := 392;
    WDS_ServiceCalls.Top := 552;
    WDS_ServiceCallsServiceCallsID.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsServiceCallsID.Name := 'WDS_ServiceCallsServiceCallsID';
    WDS_ServiceCallsServiceCallsID.FieldName := 'ServiceCallsID';
    WDS_ServiceCallsServiceCallsID.Required := True;
    WDS_ServiceCallsDate.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsDate.Name := 'WDS_ServiceCallsDate';
    WDS_ServiceCallsDate.FieldName := 'Date';
    WDS_ServiceCallsCust.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsCust.Name := 'WDS_ServiceCallsCust';
    WDS_ServiceCallsCust.FieldName := 'Cust';
    WDS_ServiceCallsIndividualCalling.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsIndividualCalling.Name := 'WDS_ServiceCallsIndividualCalling';
    WDS_ServiceCallsIndividualCalling.FieldName := 'IndividualCalling';
    WDS_ServiceCallsIndividualCalling.Size := 11;
    WDS_ServiceCallsEmergencyYN.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsEmergencyYN.Name := 'WDS_ServiceCallsEmergencyYN';
    WDS_ServiceCallsEmergencyYN.FieldName := 'EmergencyYN';
    WDS_ServiceCallsEmergencyYN.Size := 1;
    WDS_ServiceCallsWBSpecAssignNum.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsWBSpecAssignNum.Name := 'WDS_ServiceCallsWBSpecAssignNum';
    WDS_ServiceCallsWBSpecAssignNum.FieldName := 'WBSpecAssignNum';
    WDS_ServiceCallsAssignedDate.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsAssignedDate.Name := 'WDS_ServiceCallsAssignedDate';
    WDS_ServiceCallsAssignedDate.FieldName := 'AssignedDate';
    WDS_ServiceCallsWBSpecCompletedNum.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsWBSpecCompletedNum.Name := 'WDS_ServiceCallsWBSpecCompletedNum';
    WDS_ServiceCallsWBSpecCompletedNum.FieldName := 'WBSpecCompletedNum';
    WDS_ServiceCallsCompletedDate.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsCompletedDate.Name := 'WDS_ServiceCallsCompletedDate';
    WDS_ServiceCallsCompletedDate.FieldName := 'CompletedDate';
    WDS_ServiceCallsNotes.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsNotes.Name := 'WDS_ServiceCallsNotes';
    WDS_ServiceCallsNotes.DisplayWidth := 250;
    WDS_ServiceCallsNotes.FieldName := 'Notes';
    WDS_ServiceCallsNotes.Size := 250;
    WDS_ServiceCallsServiceNotes.SetParentComponent(WDS_ServiceCalls);
    WDS_ServiceCallsServiceNotes.Name := 'WDS_ServiceCallsServiceNotes';
    WDS_ServiceCallsServiceNotes.FieldName := 'ServiceNotes';
    WDS_ServiceCallsServiceNotes.Size := 10000;
    DS_ServiceCalls.SetParentComponent(Self);
    DS_ServiceCalls.Name := 'DS_ServiceCalls';
    DS_ServiceCalls.DataSet := WDS_ServiceCalls;
    DS_ServiceCalls.Left := 344;
    DS_ServiceCalls.Top := 528;
    WDS_Cust.SetParentComponent(Self);
    WDS_Cust.Name := 'WDS_Cust';
    WDS_Cust.EntitySetName := 'dlcust';
    WDS_Cust.Connection := Dm.ApiConnection;
    WDS_Cust.Left := 376;
    WDS_Cust.Top := 592;
    WDS_CustCust.SetParentComponent(WDS_Cust);
    WDS_CustCust.Name := 'WDS_CustCust';
    WDS_CustCust.FieldName := 'Cust';
    WDS_CustCustName.SetParentComponent(WDS_Cust);
    WDS_CustCustName.Name := 'WDS_CustCustName';
    WDS_CustCustName.FieldName := 'CustName';
    WDS_CustCustName.Size := 255;
    WDS_CustSRNum.SetParentComponent(WDS_Cust);
    WDS_CustSRNum.Name := 'WDS_CustSRNum';
    WDS_CustSRNum.FieldName := 'SRNum';
    WDS_CustAddress1.SetParentComponent(WDS_Cust);
    WDS_CustAddress1.Name := 'WDS_CustAddress1';
    WDS_CustAddress1.FieldName := 'Address1';
    WDS_CustAddress1.Size := 255;
    WDS_CustAddress2.SetParentComponent(WDS_Cust);
    WDS_CustAddress2.Name := 'WDS_CustAddress2';
    WDS_CustAddress2.FieldName := 'Address2';
    WDS_CustAddress2.Size := 255;
    WDS_CustCity.SetParentComponent(WDS_Cust);
    WDS_CustCity.Name := 'WDS_CustCity';
    WDS_CustCity.FieldName := 'City';
    WDS_CustCity.Size := 255;
    WDS_CustState.SetParentComponent(WDS_Cust);
    WDS_CustState.Name := 'WDS_CustState';
    WDS_CustState.FieldName := 'State';
    WDS_CustState.Size := 255;
    WDS_CustTermsExplained.SetParentComponent(WDS_Cust);
    WDS_CustTermsExplained.Name := 'WDS_CustTermsExplained';
    WDS_CustTermsExplained.FieldName := 'TermsExplained';
    WDS_CustTermsExplained.Size := 255;
    WDS_CustLatitude.SetParentComponent(WDS_Cust);
    WDS_CustLatitude.Name := 'WDS_CustLatitude';
    WDS_CustLatitude.FieldName := 'Latitude';
    WDS_CustLongitude.SetParentComponent(WDS_Cust);
    WDS_CustLongitude.Name := 'WDS_CustLongitude';
    WDS_CustLongitude.FieldName := 'Longitude';
    DS_Cust.SetParentComponent(Self);
    DS_Cust.Name := 'DS_Cust';
    DS_Cust.DataSet := WDS_Cust;
    DS_Cust.Left := 328;
    DS_Cust.Top := 576;
    WDS_Drop.SetParentComponent(Self);
    WDS_Drop.Name := 'WDS_Drop';
    WDS_Drop.AfterOpen := WDS_DropAfterOpen;
    WDS_Drop.EntitySetName := 'WW_User';
    WDS_Drop.Connection := Dm.ApiConnection;
    WDS_Drop.Left := 392;
    WDS_Drop.Top := 400;
    WDS_DropChemSpecNum.SetParentComponent(WDS_Drop);
    WDS_DropChemSpecNum.Name := 'WDS_DropChemSpecNum';
    WDS_DropChemSpecNum.FieldName := 'ChemSpecNum';
    WDS_DropChemSpecNum.Required := True;
    WDS_DropFirstName.SetParentComponent(WDS_Drop);
    WDS_DropFirstName.Name := 'WDS_DropFirstName';
    WDS_DropFirstName.FieldName := 'FirstName';
    WDS_DropFirstName.Size := 16;
    WDS_DropLastName.SetParentComponent(WDS_Drop);
    WDS_DropLastName.Name := 'WDS_DropLastName';
    WDS_DropLastName.FieldName := 'LastName';
    WDS_DropLastName.Size := 16;
    WDS_DropEmail.SetParentComponent(WDS_Drop);
    WDS_DropEmail.Name := 'WDS_DropEmail';
    WDS_DropEmail.FieldName := 'Email';
    WDS_DropEmail.Size := 16;
    WDS_DropOnlineUser.SetParentComponent(WDS_Drop);
    WDS_DropOnlineUser.Name := 'WDS_DropOnlineUser';
    WDS_DropOnlineUser.FieldName := 'OnlineUser';
    WDS_DropOnlinePass.SetParentComponent(WDS_Drop);
    WDS_DropOnlinePass.Name := 'WDS_DropOnlinePass';
    WDS_DropOnlinePass.FieldName := 'OnlinePass';
    DS_Drop.SetParentComponent(Self);
    DS_Drop.Name := 'DS_Drop';
    DS_Drop.DataSet := WDS_Drop;
    DS_Drop.Left := 352;
    DS_Drop.Top := 408;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Interval := 60000;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 352;
    WebTimer1.Top := 679;
  finally
    WebDBResponsiveGrid1.AfterLoadDFMValues;
    WP_CallCust.AfterLoadDFMValues;
    WebDBLabel1.AfterLoadDFMValues;
    WebDBLabel2.AfterLoadDFMValues;
    WebDBLabel3.AfterLoadDFMValues;
    WebDBLabel4.AfterLoadDFMValues;
    WebDBLabel5.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WP_CallDetail.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebComboBox1.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebDBMemo1.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebDBMemo2.AfterLoadDFMValues;
    WDS_ServiceCalls.AfterLoadDFMValues;
    WDS_ServiceCallsServiceCallsID.AfterLoadDFMValues;
    WDS_ServiceCallsDate.AfterLoadDFMValues;
    WDS_ServiceCallsCust.AfterLoadDFMValues;
    WDS_ServiceCallsIndividualCalling.AfterLoadDFMValues;
    WDS_ServiceCallsEmergencyYN.AfterLoadDFMValues;
    WDS_ServiceCallsWBSpecAssignNum.AfterLoadDFMValues;
    WDS_ServiceCallsAssignedDate.AfterLoadDFMValues;
    WDS_ServiceCallsWBSpecCompletedNum.AfterLoadDFMValues;
    WDS_ServiceCallsCompletedDate.AfterLoadDFMValues;
    WDS_ServiceCallsNotes.AfterLoadDFMValues;
    WDS_ServiceCallsServiceNotes.AfterLoadDFMValues;
    DS_ServiceCalls.AfterLoadDFMValues;
    WDS_Cust.AfterLoadDFMValues;
    WDS_CustCust.AfterLoadDFMValues;
    WDS_CustCustName.AfterLoadDFMValues;
    WDS_CustSRNum.AfterLoadDFMValues;
    WDS_CustAddress1.AfterLoadDFMValues;
    WDS_CustAddress2.AfterLoadDFMValues;
    WDS_CustCity.AfterLoadDFMValues;
    WDS_CustState.AfterLoadDFMValues;
    WDS_CustTermsExplained.AfterLoadDFMValues;
    WDS_CustLatitude.AfterLoadDFMValues;
    WDS_CustLongitude.AfterLoadDFMValues;
    DS_Cust.AfterLoadDFMValues;
    WDS_Drop.AfterLoadDFMValues;
    WDS_DropChemSpecNum.AfterLoadDFMValues;
    WDS_DropFirstName.AfterLoadDFMValues;
    WDS_DropLastName.AfterLoadDFMValues;
    WDS_DropEmail.AfterLoadDFMValues;
    WDS_DropOnlineUser.AfterLoadDFMValues;
    WDS_DropOnlinePass.AfterLoadDFMValues;
    DS_Drop.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.