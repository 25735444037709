program WWApp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  Login in 'Login.pas'{*.html},
  Data in 'Data.pas',
  ServiceCalls in 'ServiceCalls.pas'{*.html},
  Landing in 'Landing.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TFrmLogin, FrmLogin);
  Application.CreateFormDirect(TDm, Dm);
  //Application.CreateForm(TFrmServiceCalls, FrmServiceCalls);
  //Application.CreateForm(TFrmLanding, FrmLanding);
  Application.Run;
end.
