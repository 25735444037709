unit Data;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Connection,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TDm = class(TDataModule)
    ApiConnection: TXDataWebConnection;
    WDS_User: TXDataWebDataSet;
    DS_User: TDataSource;
    WDS_UserChemSpecNum: TIntegerField;
    WDS_UserFirstName: TStringField;
    WDS_UserLastName: TStringField;
    WDS_UserEmail: TStringField;
    WDS_UserOnlineUser: TStringField;
    WDS_UserOnlinePass: TStringField;
    WDS_UserManager: TStringField;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Dm: TDm;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

procedure TDm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  ApiConnection := TXDataWebConnection.Create(Self);
  WDS_User := TXDataWebDataSet.Create(Self);
  WDS_UserChemSpecNum := TIntegerField.Create(Self);
  WDS_UserFirstName := TStringField.Create(Self);
  WDS_UserLastName := TStringField.Create(Self);
  WDS_UserEmail := TStringField.Create(Self);
  WDS_UserOnlineUser := TStringField.Create(Self);
  WDS_UserOnlinePass := TStringField.Create(Self);
  WDS_UserManager := TStringField.Create(Self);
  DS_User := TDataSource.Create(Self);

  ApiConnection.BeforeLoadDFMValues;
  WDS_User.BeforeLoadDFMValues;
  WDS_UserChemSpecNum.BeforeLoadDFMValues;
  WDS_UserFirstName.BeforeLoadDFMValues;
  WDS_UserLastName.BeforeLoadDFMValues;
  WDS_UserEmail.BeforeLoadDFMValues;
  WDS_UserOnlineUser.BeforeLoadDFMValues;
  WDS_UserOnlinePass.BeforeLoadDFMValues;
  WDS_UserManager.BeforeLoadDFMValues;
  DS_User.BeforeLoadDFMValues;
  try
    Name := 'Dm';
    Height := 571;
    Width := 752;
    ApiConnection.SetParentComponent(Self);
    ApiConnection.Name := 'ApiConnection';
    ApiConnection.URL := 'https://www.mymvf.com:2002/tms/xdata';
    ApiConnection.Connected := True;
    ApiConnection.Left := 39;
    ApiConnection.Top := 16;
    WDS_User.SetParentComponent(Self);
    WDS_User.Name := 'WDS_User';
    WDS_User.EntitySetName := 'WW_User';
    WDS_User.Connection := ApiConnection;
    WDS_User.Left := 56;
    WDS_User.Top := 192;
    WDS_UserChemSpecNum.SetParentComponent(WDS_User);
    WDS_UserChemSpecNum.Name := 'WDS_UserChemSpecNum';
    WDS_UserChemSpecNum.FieldName := 'ChemSpecNum';
    WDS_UserChemSpecNum.Required := True;
    WDS_UserFirstName.SetParentComponent(WDS_User);
    WDS_UserFirstName.Name := 'WDS_UserFirstName';
    WDS_UserFirstName.FieldName := 'FirstName';
    WDS_UserFirstName.Size := 16;
    WDS_UserLastName.SetParentComponent(WDS_User);
    WDS_UserLastName.Name := 'WDS_UserLastName';
    WDS_UserLastName.FieldName := 'LastName';
    WDS_UserLastName.Size := 16;
    WDS_UserEmail.SetParentComponent(WDS_User);
    WDS_UserEmail.Name := 'WDS_UserEmail';
    WDS_UserEmail.FieldName := 'Email';
    WDS_UserEmail.Size := 16;
    WDS_UserOnlineUser.SetParentComponent(WDS_User);
    WDS_UserOnlineUser.Name := 'WDS_UserOnlineUser';
    WDS_UserOnlineUser.FieldName := 'OnlineUser';
    WDS_UserOnlinePass.SetParentComponent(WDS_User);
    WDS_UserOnlinePass.Name := 'WDS_UserOnlinePass';
    WDS_UserOnlinePass.FieldName := 'OnlinePass';
    WDS_UserManager.SetParentComponent(WDS_User);
    WDS_UserManager.Name := 'WDS_UserManager';
    WDS_UserManager.FieldName := 'Manager';
    WDS_UserManager.Size := 1;
    DS_User.SetParentComponent(Self);
    DS_User.Name := 'DS_User';
    DS_User.DataSet := WDS_User;
    DS_User.Left := 112;
    DS_User.Top := 192;
  finally
    ApiConnection.AfterLoadDFMValues;
    WDS_User.AfterLoadDFMValues;
    WDS_UserChemSpecNum.AfterLoadDFMValues;
    WDS_UserFirstName.AfterLoadDFMValues;
    WDS_UserLastName.AfterLoadDFMValues;
    WDS_UserEmail.AfterLoadDFMValues;
    WDS_UserOnlineUser.AfterLoadDFMValues;
    WDS_UserOnlinePass.AfterLoadDFMValues;
    WDS_UserManager.AfterLoadDFMValues;
    DS_User.AfterLoadDFMValues;
  end;
end;

end.
