unit Login;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  Data.DB, WEBLib.DB, XData.Web.JsonDataset, XData.Web.Dataset;

type
  TFrmLogin = class(TForm)
    pwd1: TEdit;
    uname1: TEdit;
    btnLogin: TButton;
    WDS_User: TXDataWebDataSet;
    DS_User: TDataSource;
    WDS_UserChemSpecNum: TIntegerField;
    WDS_UserFirstName: TStringField;
    WDS_UserLastName: TStringField;
    WDS_UserEmail: TStringField;
    WDS_UserOnlineUser: TStringField;
    WDS_UserOnlinePass: TStringField;
    procedure btnLoginClick(Sender: TObject);
    procedure WDS_UserAfterOpen(DataSet: TDataSet);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  FrmLogin: TFrmLogin;

implementation

Uses Data, Landing;

{$R *.dfm}

procedure TFrmLogin.btnLoginClick(Sender: TObject);
begin
  if uname1.text = '' then
  begin
    ShowMessage('Username Cannot be Blank');
    Exit;
  end;
  if pwd1.text = '' then
  begin
    ShowMessage('Password Cannot be Blank');
    Exit;
  end;
  WDS_User.QueryString := '$filter=(OnlineUser eq ''' +
    uname1.Text + ''')';
  WDS_User.Load;
end;

procedure TFrmLogin.WDS_UserAfterOpen(DataSet: TDataSet);
begin
  if (WDS_User.RecordCount > 0) and
    (WDS_UserOnlinePass.AsString = pwd1.Text) then
  begin
    Dm.WDS_User.QueryString := '$filter=(OnlineUser eq '''+WDS_UserOnlineUser.AsString+''')';
    Dm.WDS_User.Load;
    WDS_User.Close;
    Application.CreateForm(TFrmLanding, FrmLanding);
    FrmLanding.Show;
  end
  else
  begin
    WDS_User.Close;
    ShowMessage('Invalid Login Info');
  end;
end;

procedure TFrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  pwd1 := TEdit.Create('pwd1');
  uname1 := TEdit.Create('uname1');
  btnLogin := TButton.Create('btnLogin');
  WDS_User := TXDataWebDataSet.Create(Self);
  WDS_UserChemSpecNum := TIntegerField.Create(Self);
  WDS_UserFirstName := TStringField.Create(Self);
  WDS_UserLastName := TStringField.Create(Self);
  WDS_UserEmail := TStringField.Create(Self);
  WDS_UserOnlineUser := TStringField.Create(Self);
  WDS_UserOnlinePass := TStringField.Create(Self);
  DS_User := TDataSource.Create(Self);

  pwd1.BeforeLoadDFMValues;
  uname1.BeforeLoadDFMValues;
  btnLogin.BeforeLoadDFMValues;
  WDS_User.BeforeLoadDFMValues;
  WDS_UserChemSpecNum.BeforeLoadDFMValues;
  WDS_UserFirstName.BeforeLoadDFMValues;
  WDS_UserLastName.BeforeLoadDFMValues;
  WDS_UserEmail.BeforeLoadDFMValues;
  WDS_UserOnlineUser.BeforeLoadDFMValues;
  WDS_UserOnlinePass.BeforeLoadDFMValues;
  DS_User.BeforeLoadDFMValues;
  try
    Name := 'FrmLogin';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    pwd1.SetParentComponent(Self);
    pwd1.Name := 'pwd1';
    pwd1.Left := 37;
    pwd1.Top := 143;
    pwd1.Width := 96;
    pwd1.Height := 25;
    pwd1.ElementFont := efCSS;
    pwd1.ElementPosition := epIgnore;
    pwd1.HeightStyle := ssAuto;
    pwd1.HeightPercent := 100.000000000000000000;
    pwd1.WidthStyle := ssAuto;
    pwd1.WidthPercent := 100.000000000000000000;
    uname1.SetParentComponent(Self);
    uname1.Name := 'uname1';
    uname1.Left := 37;
    uname1.Top := 112;
    uname1.Width := 96;
    uname1.Height := 25;
    uname1.ChildOrder := 1;
    uname1.ElementFont := efCSS;
    uname1.ElementPosition := epIgnore;
    uname1.HeightStyle := ssAuto;
    uname1.HeightPercent := 100.000000000000000000;
    uname1.WidthStyle := ssAuto;
    uname1.WidthPercent := 100.000000000000000000;
    btnLogin.SetParentComponent(Self);
    btnLogin.Name := 'btnLogin';
    btnLogin.Left := 154;
    btnLogin.Top := 128;
    btnLogin.Width := 96;
    btnLogin.Height := 25;
    btnLogin.Caption := 'Login';
    btnLogin.ChildOrder := 2;
    btnLogin.ElementFont := efCSS;
    btnLogin.ElementPosition := epIgnore;
    btnLogin.HeightStyle := ssAuto;
    btnLogin.HeightPercent := 100.000000000000000000;
    btnLogin.WidthStyle := ssAuto;
    btnLogin.WidthPercent := 100.000000000000000000;
    SetEvent(btnLogin, Self, 'OnClick', 'btnLoginClick');
    WDS_User.SetParentComponent(Self);
    WDS_User.Name := 'WDS_User';
    WDS_User.AfterOpen := WDS_UserAfterOpen;
    WDS_User.EntitySetName := 'WW_User';
    WDS_User.Connection := Dm.ApiConnection;
    WDS_User.Left := 176;
    WDS_User.Top := 288;
    WDS_UserChemSpecNum.SetParentComponent(WDS_User);
    WDS_UserChemSpecNum.Name := 'WDS_UserChemSpecNum';
    WDS_UserChemSpecNum.FieldName := 'ChemSpecNum';
    WDS_UserChemSpecNum.Required := True;
    WDS_UserFirstName.SetParentComponent(WDS_User);
    WDS_UserFirstName.Name := 'WDS_UserFirstName';
    WDS_UserFirstName.FieldName := 'FirstName';
    WDS_UserFirstName.Size := 16;
    WDS_UserLastName.SetParentComponent(WDS_User);
    WDS_UserLastName.Name := 'WDS_UserLastName';
    WDS_UserLastName.FieldName := 'LastName';
    WDS_UserLastName.Size := 16;
    WDS_UserEmail.SetParentComponent(WDS_User);
    WDS_UserEmail.Name := 'WDS_UserEmail';
    WDS_UserEmail.FieldName := 'Email';
    WDS_UserEmail.Size := 16;
    WDS_UserOnlineUser.SetParentComponent(WDS_User);
    WDS_UserOnlineUser.Name := 'WDS_UserOnlineUser';
    WDS_UserOnlineUser.FieldName := 'OnlineUser';
    WDS_UserOnlinePass.SetParentComponent(WDS_User);
    WDS_UserOnlinePass.Name := 'WDS_UserOnlinePass';
    WDS_UserOnlinePass.FieldName := 'OnlinePass';
    DS_User.SetParentComponent(Self);
    DS_User.Name := 'DS_User';
    DS_User.DataSet := WDS_User;
    DS_User.Left := 232;
    DS_User.Top := 288;
  finally
    pwd1.AfterLoadDFMValues;
    uname1.AfterLoadDFMValues;
    btnLogin.AfterLoadDFMValues;
    WDS_User.AfterLoadDFMValues;
    WDS_UserChemSpecNum.AfterLoadDFMValues;
    WDS_UserFirstName.AfterLoadDFMValues;
    WDS_UserLastName.AfterLoadDFMValues;
    WDS_UserEmail.AfterLoadDFMValues;
    WDS_UserOnlineUser.AfterLoadDFMValues;
    WDS_UserOnlinePass.AfterLoadDFMValues;
    DS_User.AfterLoadDFMValues;
  end;
end;

end.